var Fusion = Fusion || {};
Fusion.getLocalisedDate = function(date, timezone)
{
	if(timezone === undefined)
	{
		if(orgData.timezone === undefined)
		{
			timezone = 'Europe/London';
		}
		else
		{
			timezone = orgData.timezone;
		}
	}

	if(typeof date == "string") {
        if (!date.endsWith('Z')) {
            date = '' + date + 'Z';
        }
    }
	var m = moment(date);
	m.locale('en-gb');
	m.tz(timezone);
	return m.format("D MMMM YYYY HH:mm:ss");
};