var Fusion = Fusion || {};
Fusion.CSVHelper = function() {
	
	var generate = function(headers, data) {
		var csvContent = "data:text/csv;charset=utf-8,";
		
		csvContent += headers.join(",") + "\n";
			
		data.forEach(function(dataArray, index){
			var dataString = dataArray.join(",");
			csvContent += index < data.length ? dataString + "\n" : dataString;
		});
		
		return csvContent;
	};
	
	var download = function(filename, csvContent) {
		var encodedUri = encodeURI(csvContent);
		var link 	   = document.createElement('a');
		
		link.setAttribute('href', encodedUri);
		link.setAttribute('download', Fusion.getLocalisedDate(Date.now()).replace(' ','-') + '-' + filename + '.csv');
		link.setAttribute('target', '_blank');
		link.click();	
	};
	
	var getCSV = function(filename, headers, data) {
		var csvContent = generate(headers, data);
		download(filename, csvContent);
	};
		
	return {
		getCSV: getCSV
	};
	
}();

//click polyfill for firefox
HTMLElement.prototype.click = function() {
   var evt = this.ownerDocument.createEvent('MouseEvents');
   evt.initMouseEvent('click', true, true, this.ownerDocument.defaultView, 1, 0, 0, 0, 0, false, false, false, false, 0, null);
   this.dispatchEvent(evt);
};