var Fusion = Fusion || {};
Fusion.messageStatusColour = function(){

	var colours = {
		'Sent'         : 'blue',
		'Received'     : 'blue',
		'Timed-Out'    : 'red',
		'Opened'       : 'yellow',
		'Acknowledged' : 'yellow',
		'Responded'    : 'green',
		'Declined'     : 'purple'
		
	};

	var get = function(status) {
		return colours[status];	
	};
	
	return {
		get: get
	};	
	
}();