var Fusion = Fusion || {};
Fusion.search = function() {
	
	var execute = function(index, searchData, success, failure, always){
		
		Fusion.ajax.post(
			Fusion.config.ESURL + index,
			searchData,
			success,
			failure,
			always
		);	
	};
	
	return {
		execute: execute
	};
	
}();
	