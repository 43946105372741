var Fusion = Fusion || {};
Fusion.serviceMapController = function(){
	
	var init = function() {
		ko.applyBindings(viewModel);
	};

	var searchWaiter = null;
	var searchBarListener = function(data, event) {
		if(searchWaiter != null) {
			//console.log("Service Map Search: Clearing Timeout");
            clearTimeout(searchWaiter);
        }

        if(event.keyCode === 13) {
            //console.log("Service Map Search: Return pressed");
            executeSearch();
        }
		else {
            //console.log("Service Map Search: Setting Timeout");
            searchWaiter = setTimeout(function() { executeSearch(); }, 750);
        }
	};
	
	var executeSearch = function() {
		searchWaiter = null;
		//This method is called on text field change, dropdown change, or button click
		var searchTerm = viewModel.searchTerm();
		var workspacegroupUid = viewModel.workspacegroupUid();

        //console.log("Service Map Search: Execute Search: " + searchTerm + " : " + workspacegroupUid);

        if(searchTerm !== undefined){
			//Only go through with the search if we have a search term
			
			//Set the search in progress flag to true to show the loader
			viewModel.searchInProgress(true);
			
	        poffset = $("#mapsearch").offset();
	        poffset.top -= $("#map_search_results_panel").height();
	        $("#map_search_results_panel").css("left",poffset.left - 125);
	        $("#map_search_results_panel").css("top", poffset.top - 9);
	        $("#map_search_results_panel").fadeIn("fast");
			
			var searchResults = Fusion.address.search(
				{
					//search data
					searchTerm: searchTerm, 
					workspacegroupUid: workspacegroupUid
				},
				function(response){
					//success
					viewModel.searchInProgress(false);
					
					//Set the search results array in viewmodel to our search response
					viewModel.searchResults(response.data);
					
		            poffset = $("#mapsearch").offset();
		            poffset.top -= $("#map_search_results_panel").height();
		            $("#map_search_results_panel").css("left",poffset.left - 125);
		            $("#map_search_results_panel").css("top", poffset.top - 9);
		            $("#map_search_results_panel").fadeIn("fast");
		            $("#closesearch").click(function(){
		                hideSearchResults();
		            });
					
					//MIMIC OLD BEHAVIOR OF SERVICE MAP PANEL OPENING
				},
				function(response){
					//fail
					console.log(response.data);
				},
				function(){
					//always
					//Set the search in progress flag to false to hide the loader regardless of error or success
					viewModel.searchInProgress(false);
				}
			);
		}
	};
	
	var loadSearchResult = function(data) {
		var slid = data.servicelocationsuids;
		var point = data.data.point;
		var name = data.address;
		var comment = "";
		var routeUid = null;
		var workspaceGroupUid = data.data.workspacegroupuid;
		
        if (slid.indexOf('serviceLocationsUid') == -1) {
            slid = 'serviceLocationsUid=' + slid;
        }

        // Create the overlay instance if necessary.
        if((marker_overlay === null || marker_overlay === undefined) && map !== undefined)
        {
            //console.log("Creating Marker Overlay")
            marker_overlay = new ol.Overlay({
                positioning: 'bottom-center',
                element: document.getElementById('lvmapmarker')
            });
            map.addOverlay(marker_overlay);
        }
        
        if(comment !== null && typeof comment === "object")
        {
            comment = comment.get('servicecomments');
        }
        
        //get a marker, move it to the point, change it's name, zoom to location. Simple.
        $("#map_search_results_panel").fadeOut("fast");
        $("#lvmapmarker").html('<div class="searchmarker"><a href="#" class=\"searchmarkerclose\" onClick=\"closeoverlay();\"><span class="fa fa-times"></span></a>' + ((slid == 'serviceLocationsUid=')?'': '<div id="lvmarker-calender"><a href="/find.php?'+slid+'"><span class="fa fa-calendar"></span></a></div>' ) + '<div id="lvmarker-content">'+name+'<br />'+comment+'</div></div>');
		
		
		//Loop through available routes in the route list,
		//Load route for service location if the routeUid is 
		//in the list of available routes
		var availableRoutes = [];
        $('.sm_route.routeclicktarget').each(function(){
	        availableRoutes.push($(this).attr('route'));
	        
	        if($(this).attr('route') == routeUid)
	        {
		        //Show only route for this 
				loadroute(routeUid);
	        }
	    });
	    //Hide any open routes that aren't in the list of 
	    //available routes
		if(loadedroutes !== null) {
            for (var i = 0; i < loadedroutes.length; i++) {
                if (routeUid != loadedroutes[i]) {
                    hideroute(loadedroutes[i]);
                }
            }
        }

        $("#lvmapmarker").addClass('lvlevelnone');
        var ll = point.split(" ");
        var longitude = parseFloat(ll[0]);
        var latitude = parseFloat(ll[1]);
        var coordinate = [longitude, latitude];
        //var pos = ol.proj.transform(new Array(ll[0],ll[1]), 'EPSG:3857', map.getView().getProjection().getCode());
        marker_overlay.setPosition(coordinate);
        $("#lvmapmarker").css('display', 'block');

        if ($("#routezoom").val()) {
            route = $("#routezoom").val();
            zoomtolayerextent(route);
            initialZoom = false;
            $("#routezoom").val(undefined);
        } else {

            var view = map.getView();
            pan_to_point(coordinate);
            if(view.getZoom()<18)
            {
                zoom_to(18);
            }
        }
	};
	
	//Set up knockout view model
	var viewModel = {
		searchTerm: ko.observable(),
		workspacegroupUid: ko.observable(),
		searchInProgress: ko.observable(false),
		searchResults: ko.observableArray()
	};
	
	return {
		init: init,
		searchBarListener: searchBarListener,
		loadSearchResult: loadSearchResult,
		viewModel: viewModel	
	};
	
}();