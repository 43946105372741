var Fusion = Fusion || {};
Fusion.find = function() {
	
	var get = function(serviceLocationUid, isStreetService, success, failure, always){
		Fusion.ajax.get(
			Fusion.config.APIURL + '/find/' + isStreetService + '/' + serviceLocationUid,
			{},
			success,
			failure,
			always
		);	
	};

	var post = function(serviceLocationUid, isStreetService, success, failure, always){
		Fusion.ajax.post(
			Fusion.config.APIURL + '/find/',
			{
			    'servicelocations': serviceLocationUid,
                'isStreetService': isStreetService
            },
			success,
			failure,
			always
		);
	};
	
	return {
		post: post,
		get: get
	};
	
}();
	