var Fusion = Fusion || {};
Fusion.messagingController = function(){
	
	var init = function(){
		loadMessageList();

        ko.subscribable.fn.trimmed = function() {
            return ko.computed({
                read: function() {
                    return this().trim();
                },
                write: function(value) {
                    this(value.trim());
                    this.valueHasMutated();
                },
                owner: this
            }).extend({ notify: 'always' });
        };

		ko.applyBindings(viewModel);
	};
	
	var loadMessageList = function(){
		viewModel.messageList([]);
		viewModel.loadingMessage('Loading messages please wait...');
		Fusion.messagingService.get(
			function(response){
				viewModel.messageList(response.data);
				if(response.data.length == 0 && response.error == false)
				{
					// No message to display.
					viewModel.loadingMessage('No messages');
				}
			},
			function(response){
				console.log(response);
			}
		);
	};
	
	var loadResponseMessages = function(messageData){
		Fusion.messagingService.getResponses(
			messageData.MessageUID,
			messageData.AddressUID,
			function(response){
				viewModel.responseMessages(response.data);
				viewModel.responseMessagesLoaded(true);
			},
			function(response){
				viewModel.responseMessagesLoaded(true);
			}
		);
	};
	
	var loadRecipients = function(){
		viewModel.recipientsLoaded(false);
		Fusion.messagingService.getRecipients(
			function(response){
				viewModel.allRecipients(response.data);
				viewModel.recipientsLoaded(true);
			},
			function(response){
			}
		);
	};
	
	var loadMessage = function(data, event)	{
		$('.messages-sidebar-list-item').removeClass('selected');
		$(event.currentTarget).addClass('selected');
		viewModel.currentMessage(null);
		viewModel.currentMessage(data);
		viewModel.responseMessagesLoaded(false);
		viewModel.responseMessages([]);
		loadResponseMessages(data);
	};
	
	var openSendMessage = function() {
		viewModel.chosenRecipients([]);
		viewModel.allRecipients([]);
		viewModel.selectedRecipients([]);
		viewModel.recipientsLoaded(false);
		viewModel.sendMessageTitle(null); 
		viewModel.sendMessageContent(null);
		viewModel.sendMessageType('Notification');
		viewModel.questionResponseYes(false);
		viewModel.questionResponseNo(false);
		
		$('.recipients-chooser-list').removeClass('open');	
		$('.recipients-chooser-add-button').show();
		
		$('#sendMessageModal').modal('show');
	};
	
	var openRecipientChooser = function() {
		$('.recipients-chooser-list').addClass('open');	
		$('.recipients-chooser-add-button').hide();
		loadRecipients();
	};
	
	var chooseRecipient = function(data, event) {
		var chosenRecipients = viewModel.chosenRecipients();
		var selectedRecipients = viewModel.selectedRecipients();
		
		var recipientIndex = jQuery.inArray(data.device, selectedRecipients);
		
		if(recipientIndex < 0)
		{
			chosenRecipients.push(data);
			selectedRecipients.push(data.device);
		}
		else
		{
			chosenRecipients.splice(recipientIndex, 1);
			selectedRecipients.splice(recipientIndex, 1);
			
		}
		
		viewModel.chosenRecipients(chosenRecipients);
		viewModel.selectedRecipients(selectedRecipients);
	};
	
	var sendMessage = function() {
		Fusion.messagingService.post(
			viewModel.selectedRecipients().join(','),
			viewModel.sendMessageTitle(), 
			viewModel.sendMessageContent(),
			viewModel.sendMessageType(),
			viewModel.questionResponseYes(),
			viewModel.questionResponseNo(),
			function(response){
				$('#sendMessageModal').modal('hide');
				loadMessageList();
				viewModel.successMessage(true);
				setTimeout(function(){ viewModel.successMessage(false); }, 6000);
			},
			function(){
				console.log(response);
			}
		);
	};

	//Set up knockout view model
	var viewModel = {
		allRecipients: ko.observable([]),
		chosenRecipients: ko.observable([]),
		selectedRecipients: ko.observable([]),
		messageList: ko.observable([]),
		responseMessages: ko.observable([]),
		responseMessagesLoaded: ko.observable(false),
		recipientsLoaded: ko.observable(false),
		currentMessage: ko.observable(null),
		sendMessageType: ko.observable('Notification'),
		sendMessageTitle: ko.observable(null),
		sendMessageContent: ko.observable(null),
		questionResponseYes: ko.observable(false),
		questionResponseNo: ko.observable(false),
		successMessage: ko.observable(false),
		loadMessage: loadMessage,
		chooseRecipient: chooseRecipient,
		loadingMessage: ko.observable('')
	};
	
	return {
		init: init,
		loadMessageList: loadMessageList,
		openSendMessage: openSendMessage,
		openRecipientChooser: openRecipientChooser,
		sendMessage: sendMessage,
		viewModel: viewModel
	};
	
}();