var Fusion = Fusion || {};
Fusion.mapUrl = function(){
	
	var get = function(serviceLocationUids, routeUids, extraParams) {
		routeUids   		= (routeUids === undefined) ? null : routeUids;
		extraParams 		= (extraParams === undefined) ? null : extraParams;
		
	    if(!Array.isArray(serviceLocationUids)) {
			serviceLocationUids = [serviceLocationUids];  
	    }
	
	    if (routeUids !== null && !Array.isArray(routeUids)) {
	        routeUids = [routeUids];
	    }
	
	    var queryData = {};
	
	    if (Array.isArray(routeUids) && routeUids !== '') {
	        queryData.routesUid = routeUids;
	    }
	
	    return '/servicemap.php?' + buildUrlString(serviceLocationUids, queryData) + ((extraParams !== null) ? extraParams : '');
	};
	
	var buildUrlString = function(serviceLocationUids, optionalExtras){
		optionalExtras.serviceLocationsUid = serviceLocationUids;
        
        return decodeURIComponent(buildHttpQuery(optionalExtras)).replace(/\[([0-9]+)\]/g, '[]');
	};
	
	// 3rd party method to emulate php http_build_query
	var buildHttpQuery = function (obj, num_prefix, temp_key) {
		var output_string = [];
		
		Object.keys(obj).forEach(function (val) {
			var key = val;
			
			num_prefix && !isNaN(key) ? (key = num_prefix + key) : '';
			
			key = encodeURIComponent(key.replace(/[!'()*]/g, escape));
			
			temp_key ? (key = temp_key + '[' + key + ']') : '';
			
			if (typeof obj[val] === 'object') {
				var query = buildHttpQuery(obj[val], null, key);
				output_string.push(query);
			} else {
				var value = encodeURIComponent(obj[val].replace(/[!'()*]/g, escape));
				output_string.push(key + '=' + value);
			}
		});
		
		return output_string.join('&');
	};
	
	return	{
		get: get
	};
	
}();