var Fusion = Fusion || {};
Fusion.messagingConfigController = function(){
	
	var init = function(){
		ko.applyBindings(viewModel);
	};
	
	var saveSettings = function(){
		viewModel.successMessage(false);
		if(viewModel.hasConfig() === false){
			//Create config
			Fusion.messagingConfigService.post(
				viewModel.workspaceGroup(),
				viewModel.enableMessaging(),
				viewModel.allowFreeText(),
				viewModel.emailForwardAddress(),
				function(response){
					console.log(response);
					viewModel.hasConfig(true);
					viewModel.successMessage('Settings created successfully!');
					setTimeout(function(){ viewModel.successMessage(false); }, 6000);
				},
				function(response){
					console.log(response);
				}
			);
		}
		else
		{
			//Update config
			
			Fusion.messagingConfigService.put(
				viewModel.configId(),
				viewModel.workspaceGroup(),
				viewModel.enableMessaging(),
				viewModel.allowFreeText(),
				viewModel.emailForwardAddress(),
				function(response){
					console.log(response);
					viewModel.successMessage('Settings saved successfully!');
					setTimeout(function(){ viewModel.successMessage(false); }, 6000);
				},
				function(response){
					console.log(response);
				}
			);
		}
	};
	
	var getPreDefinedMessages = function(){
		Fusion.messagingPreDefinedService.get(
			viewModel.workspaceGroup(),
			function(response){
				console.log(response.data);
				viewModel.preDefinedMessages(response.data);
				viewModel.preDefinedMessagesLoaded(true);
				viewModel.hasPreDefinedMessages(true);
				console.log(viewModel);
				
			},
			function(response){
				console.log(response);
				viewModel.preDefinedMessagesLoaded(true);
				viewModel.hasPreDefinedMessages(false);
			}
		);	
	};
	
	var selectWorkspaceGroup = function(data){
		viewModel.configLoaded(false);
		viewModel.configId(false);
		viewModel.allowFreeText(true);
		viewModel.enableMessaging(true);
		viewModel.emailForwardAddress('');
		viewModel.emailNewMessages(false);
		viewModel.hasConfig(false);
		viewModel.successMessage(false);
		viewModel.preDefinedMessagesLoaded(false);
		viewModel.hasPreDefinedMessages(false);
		viewModel.preDefinedMessages([]);
		
		Fusion.messagingConfigService.get(
			viewModel.workspaceGroup(),
			function(response){
				console.log(response.data);
				viewModel.allowFreeText(response.data.allowfreetext);
				viewModel.configId(response.data.messaging_configid);
				if(response.data.emailforwardaddress !== "")
				{
					viewModel.emailForwardAddress(response.data.emailforwardaddress);
					viewModel.emailNewMessages(true);
				}
				viewModel.configLoaded(true);
				viewModel.hasConfig(true);
				console.log(viewModel);
				
			},
			function(response){
				console.log(response);
				viewModel.configLoaded(true);
				viewModel.hasConfig(false);
			}
		);
		
		getPreDefinedMessages();
	};
	
	var openAddPreDefinedMessage = function(){	
		viewModel.preDefinedMessageContent('');
		viewModel.preDefinedMessageType('Text');
		$('#addPreDefinedMessageModal').modal('show');
	};
	
	var addPreDefinedMessage = function(){
		Fusion.messagingPreDefinedService.post(
			viewModel.workspaceGroup(),
			viewModel.preDefinedMessageContent(),
			viewModel.preDefinedMessageType(),
			(viewModel.preDefinedMessages().length + 1),
			function(response){
				console.log(response);
				viewModel.hasPreDefinedMessages(true);
				
				viewModel.successMessage('Pre-defined message added successfully!');
				setTimeout(function(){ viewModel.successMessage(false); }, 6000);
				
				$('#addPreDefinedMessageModal').modal('hide');
				
				getPreDefinedMessages();
			},
			function(response){
				console.log(response);
				$('#addPreDefinedMessageModal').modal('hide');
			}
		);
	};
	
	var openEditPreDefinedMessage = function(data){
		console.log(data);
		viewModel.editPreDefinedMessageContent(data.message);
		viewModel.editPreDefinedMessageType(data.detailstype);
		viewModel.editPreDefinedMessageSortOrder(data.sortorder);
		viewModel.editPreDefinedMessageId(data.messaging_predefinedmessageid);
		$('#editPreDefinedMessageModal').modal('show');
	};
	
	var editPreDefinedMessage = function(){
		Fusion.messagingPreDefinedService.put(
			viewModel.editPreDefinedMessageId(),
			viewModel.workspaceGroup(),
			viewModel.editPreDefinedMessageContent(),
			viewModel.editPreDefinedMessageType(),
			viewModel.editPreDefinedMessageSortOrder(),
			function(response){
				
				viewModel.successMessage('Pre-defined message edited successfully!');
				setTimeout(function(){ viewModel.successMessage(false); }, 6000);
				
				$('#editPreDefinedMessageModal').modal('hide');
				
				getPreDefinedMessages();
			},
			function(response){
				console.log(response);
				$('#editPreDefinedMessageModal').modal('hide');
			}
		);
	};
	
	var openDeletePreDefinedMessage = function(data){
		console.log(data);
		viewModel.deletePreDefinedMessageId(data.messaging_predefinedmessageid);
		$('#deletePreDefinedMessageModal').modal('show');
	};
	
	var deletePreDefinedMessage = function(){
		Fusion.messagingPreDefinedService.del(
			viewModel.deletePreDefinedMessageId(),
			viewModel.workspaceGroup(),
			function(response){
				
				viewModel.successMessage('Pre-defined message deleted successfully!');
				setTimeout(function(){ viewModel.successMessage(false); }, 6000);
				
				$('#deletePreDefinedMessageModal').modal('hide');
				
				getPreDefinedMessages();
			},
			function(response){
				console.log(response);
				$('#deletePreDefinedMessageModal').modal('hide');
			}
		);
	};
	
	var closeDeletePreDefinedMessage = function()
	{
		$('#deletePreDefinedMessageModal').modal('hide');
	};
	
	var viewModel = {
		hasConfig: ko.observable(false),
		configLoaded: ko.observable(false),
		configId: ko.observable(false),
		allowFreeText: ko.observable(true),
		enableMessaging: ko.observable(true),
		emailNewMessages: ko.observable(false),
		emailForwardAddress: ko.observable(''),
		workspaceGroup: ko.observable('none'),
		successMessage: ko.observable(false),
		preDefinedMessages: ko.observable([]),
		preDefinedMessagesLoaded: ko.observable(false),
		hasPreDefinedMessages: ko.observable(false),
		preDefinedMessageContent: ko.observable(''),
		preDefinedMessageType: ko.observable('Text'),
		editPreDefinedMessageContent: ko.observable(''),
		editPreDefinedMessageType: ko.observable('Text'),
		editPreDefinedMessageSortOrder: ko.observable(),
		editPreDefinedMessageId: ko.observable(),
		deletePreDefinedMessageId: ko.observable(),
		saveSettings: saveSettings,
		selectWorkspaceGroup: selectWorkspaceGroup,
		openAddPreDefinedMessage: openAddPreDefinedMessage,
		addPreDefinedMessage: addPreDefinedMessage,
		openEditPreDefinedMessage: openEditPreDefinedMessage,
		editPreDefinedMessage: editPreDefinedMessage,
		openDeletePreDefinedMessage: openDeletePreDefinedMessage,
		deletePreDefinedMessage: deletePreDefinedMessage,
		closeDeletePreDefinedMessage: closeDeletePreDefinedMessage
	};
	
	return {
		init: init
	};
	
}();