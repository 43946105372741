var Fusion = Fusion || {};
Fusion.labelColours = [
	{
		full: '#0053a7', //blue
		bg: '#85acd5',
		bgtext: '#000',
		fulltext: '#FFF'
	},
	{
		full: '#fff400', //yellow
		bg: '#fffa85',
		bgtext: '#000',
		fulltext: '#000'
	},
	{
		full: '#f23c2e', //red
		bg: '#f9a19b',
		bgtext: '#000',
		fulltext: '#FFF'
	},
	{
		full: '#f99000', //orange
		bg: '#fcca85',
		bgtext: '#000',
		fulltext: '#FFF'
	},
	{
		full: '#f0008c',//megenta
		bg: '#f0008c',
		bgtext: '#000',
		fulltext: '#FFF'
	},
	{
		full: '#00acf2',//lightblue
		bg: '#85d7f9',
		bgtext: '#000',
		fulltext: '#FFF'
	},
	{
		full: '#00a98f',//teal
		bg: '#85d6c9',
		bgtext: '#000',
		fulltext: '#FFF'
	},
	{
		full: '#a2915f',//gold
		bg: '#d2cab2',
		bgtext: '#000',
		fulltext: '#FFF'
	},
	{
		full: '#c686bf',//purple
		bg: '#d4a3cf',
		bgtext: '#000',
		fulltext: '#FFF'
	},
	{
		full: '#d2cab2',//pale green
		bg: '#d4a3cf',
		bgtext: '#000',
		fulltext: '#FFF'
	},
	{
		full: '#ffb3ff',//pink
		bg: '#ffe6ff',
		bgtext: '#000',
		fulltext: '#FFF'
	},
	{
		full: '#ffff99',//sand
		bg: '#ffffcc',
		bgtext: '#000',
		fulltext: '#FFF'
	},
	{
		full: '#c3c388',//forest
		bg: '#ddddbb',
		bgtext: '#000',
		fulltext: '#FFF'
	},
	{
		full: '#99ffeb',//sea
		bg: '#ccfff5',
		bgtext: '#000',
		fulltext: '#FFF'
	},
	{
		full: '#9999ff',//sky
		bg: '#ccccff',
		bgtext: '#000',
		fulltext: '#FFF'
	},
	{
		full: '#ffb380',//orange
		bg: '#ffd1b3',
		bgtext: '#000',
		fulltext: '#FFF'
	}
];


