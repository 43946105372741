var Fusion = Fusion || {};
Fusion.serviceHistory = function() {
	
	var get = function(serviceLocationUid, success, failure, always){
		Fusion.ajax.get(
			Fusion.config.APIURL + '/servicehistory/' + serviceLocationUid,
			{},
			success,
			failure,
			always
		);	
	};
	
	return {
		get: get
	};
	
}();
	