var Fusion = Fusion || {};
Fusion.messagingService = function() {
	
	var get = function(success, failure, always){
		Fusion.ajax.get(
			Fusion.config.APIURL + '/messaging/',
			{},
			success,
			failure,
			always
		);	
	};
	
	var getResponses = function(messageUID, addressUID, success, failure, always){
		Fusion.ajax.get(
			Fusion.config.APIURL + '/messaging/response/' + messageUID + '/' + addressUID,
			{},
			success,
			failure,
			always
		);	
	};
	
	var getRecipients = function(success, failure, always){
		Fusion.ajax.get(
			Fusion.config.APIURL + '/messaging/recipients',
			{},
			success,
			failure,
			always
		);	
	};
	
	var post = function(addressUIDs, title, content, type, responseYes, responseNo, success, failure, always){
		Fusion.ajax.post(
			Fusion.config.APIURL + '/messaging/',
			{
				addressUID: addressUIDs,
				title: title,
				content: content,
				type: type,
				responseNo: responseNo,
				responseYes: responseYes
			},
			success,
			failure,
			always
		);	
	};
	
	return {
		get: get,
		getResponses: getResponses,
		getRecipients: getRecipients,
		post: post
	};
	
}();
	