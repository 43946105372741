var Fusion = Fusion || {};
Fusion.ajax = function() {
	
	//private
	var send = function(method, url, data, success, failure, always){
		$.ajax({
			method: method,
			url: url,
			data: data,
			headers: { 'Session': Fusion.cookie.get('sessid') }
		})
		.done(function(response) {
			if(success !== undefined) success(response);
			if(success === undefined) console.debug(response);
		})
		.fail(function(response) {
			if(failure !== undefined) failure(response);
			if(failure === undefined) console.debug(response);
		})
		.always(function(response) {
			if(always !== undefined) always(response);
		});
	};
	
	var post = function(url, data, success, failure, always){
		send('POST', url, data, success, failure, always);
	};
	
	var get = function(url, data, success, failure, always){
		send('GET', url, data, success, failure, always);
	};
	
	var put = function(url, data, success, failure, always){
		send('PUT', url, data, success, failure, always);
	};
	
	var del = function(url, data, success, failure, always){
		send('DELETE', url, data, success, failure, always);
	};
	
	return {
		post: post,
		get: get,
		put: put,
		del: del
	};
	
}();