var Fusion = Fusion || {};
Fusion.reportingRouteDetailController = function(){
	
	var allSegmentsTable        = {};
	var completedSegmentsTable  = {};
	var incompleteSegmentsTable = {};
	var overrideSegmentsTable   = {};
	var availableOverrideSegmentsTable = {};

	var isStreetService = false;
		
	//Set up knockout view model
	var viewModel = {
		tableData: {
			all: ko.mapping.fromJS([]),
			complete: ko.mapping.fromJS([]),
			incomplete: ko.mapping.fromJS([]),
			override: ko.mapping.fromJS([]),
			availableOverride: ko.mapping.fromJS([])
		},
		dates: ko.observable(),
		routeData: ko.observable(),
		totals: ko.observable(),
		tableLoaded: ko.observable(false)
	};

	var transformForCSV = function(knockoutArray, isOverrideCsv, hideAddressColumns) {
		var data = ko.mapping.toJS(knockoutArray);
		var transformed = [];
		
		data.forEach(function(serviceHistoryItemModel, index) {
			var csvData = [];

			if (!isOverrideCsv) {
                csvData.push(serviceHistoryItemModel.sequence);
                csvData.push(serviceHistoryItemModel.segmentid);
                csvData.push(serviceHistoryItemModel.arcid);
                csvData.push('"' + serviceHistoryItemModel.routestreetname + '"'); // Add quotes around 'Service From'
				if (!hideAddressColumns) {
                    csvData.push('"' + serviceHistoryItemModel.address + '"'); //Add quotes around address
                    csvData.push(serviceHistoryItemModel.addressid);
                }
                csvData.push(serviceHistoryItemModel.servicedAt);
            }
            else {
                csvData.push(serviceHistoryItemModel.sequence);
                csvData.push(serviceHistoryItemModel.segmentid);
                csvData.push(serviceHistoryItemModel.arcid);
                csvData.push('"' + serviceHistoryItemModel.routestreetname + '"'); // Add quotes around 'Service From'

				if (!hideAddressColumns) {
                    csvData.push('"' + serviceHistoryItemModel.address + '"'); //Add quotes around address
                    csvData.push(serviceHistoryItemModel.addressid);
                }

                csvData.push('"' + serviceHistoryItemModel.username + '"');
                csvData.push('"' + serviceHistoryItemModel.status + '"');
                csvData.push('"' + serviceHistoryItemModel.reason + '"');
                csvData.push(serviceHistoryItemModel.servicedAt);
                csvData.push('"' + serviceHistoryItemModel.lastmodified + '"');
            }
			
			transformed.push(csvData);
		});
		
		return transformed;
	};

	var csvColumnOrder = function(isOverrideCsv, hideAddressColumns) {
		var columns = [];
		columns.push('Sequence');
		columns.push('Segment ID');
		columns.push('Arc ID');
		columns.push('Service From');

		if (!hideAddressColumns) {
			columns.push('Address');
			columns.push('Address ID');
		}

		if (!isOverrideCsv) {
			columns.push('Serviced At');
        }
		else {
			columns.push('User');
			columns.push('Status');
			columns.push('Reason');
			columns.push('Serviced At');
			columns.push('Last Modified');
        }

		return columns;
	};
	
	var downloadCSV = function(table) {
		var filename = viewModel.routeData().workspacename + '-' + viewModel.routeData().solutionname + '-' + viewModel.routeData().routename;
		
		switch(table) {
			case 'all':
				Fusion.CSVHelper.getCSV('all-' + filename, csvColumnOrder(false, false), transformForCSV(viewModel.tableData.all, false, false));
			break;

			case 'complete':
				Fusion.CSVHelper.getCSV('completed-' + filename, csvColumnOrder(false, false), transformForCSV(viewModel.tableData.complete, false, false));
			break;

			case 'incomplete':
				Fusion.CSVHelper.getCSV('incomplete-' + filename, csvColumnOrder(false, false), transformForCSV(viewModel.tableData.incomplete, false, false));
			break;

			case 'override':
				Fusion.CSVHelper.getCSV('override-' + filename, csvColumnOrder(true, true), transformForCSV(viewModel.tableData.override, true, true));
			break;
			
			case 'availableOverride':
				Fusion.CSVHelper.getCSV('original-' + filename, csvColumnOrder(false, true), transformForCSV(viewModel.tableData.availableOverride, false, true));
			break;
		}	
	};
	
	var init = function(){
		ko.applyBindings(viewModel);
	};

	var parseData = function(originalData, parseType) {
		var clonedData = JSON.parse(JSON.stringify(originalData));
		var toReturn = [];

        var useOverrides = true;

		$.each(clonedData, function(arcId, sequenceData){
			$.each(sequenceData, function(sequence, arcData) {
                switch(parseType) {
                    case 'availableOverride':
                        useOverrides = false;
                        // Fall through to all

					case 'all':
						if (useOverrides && (arcData.override !== undefined)) {
							if (!arcData.override.serviced || (arcData.override.serviced && (arcData.override.type === 'outsideDateRange'))) {
								arcData.servicedAt = "N/A";
								toReturn.push(arcData);
                            }
                            else if (arcData.override.serviced && (arcData.override.type === 'insideDateRange')) {
								arcData.servicedAt = arcData.override.eventdt;
								toReturn.push(arcData);
                            }
                        }
                        else {
                            toReturn.push(arcData);
                        }
						break;

					case 'complete':
						if (arcData.override !== undefined) {
							if (arcData.override.serviced && (arcData.override.type === 'insideDateRange')) {
								arcData.servicedAt = arcData.override.eventdt;
								toReturn.push(arcData);
                            }
                        }
						else if (arcData.type === 'complete') {
							toReturn.push(arcData);
                        }
                        break;

					case 'incomplete':
						if (arcData.override !== undefined) {
							if (!arcData.override.serviced || (arcData.override.serviced && (arcData.override.type === 'outsideDateRange'))) {
								arcData.servicedAt = "N/A";
								toReturn.push(arcData);
                            }
                        }
						else if (arcData.type === 'incomplete') {
							toReturn.push(arcData);
                        }
                        break;

					case 'override':
						if (arcData.override !== undefined) {
							if (!arcData.override.serviced) {
								arcData.servicedAt = 'N/A';
                            }
                            else {
                                    arcData.servicedAt = arcData.override.eventdt;
                            }
							toReturn.push(arcData);
                        }
                        break;
                }
            });
		});

		if (!isStreetService) {
		    var newToReturn = [];

            // If it's not a street service then we need to do extra parsing depending on the parseType required
            switch (parseType) {
                case 'all':
                case 'complete':
                case 'incomplete':
                    // These tables need to show every individual service location
                    $.each(toReturn, function (index, arcData) {
                        $.each(arcData.servicelocations, function(randomIndex, servicelocation) {
                            var toAdd = JSON.parse(JSON.stringify(arcData));
                            delete toAdd.servicelocations;
                            toAdd.segmentData = servicelocation;
                            newToReturn.push(toAdd);
                        });
                    });
                    break;

                case 'override':
                case 'availableOverride':
                    // These tables group instead by the arcId
                	var organizedData = {};
                    $.each(toReturn, function (index, arcData) {
                        $.each(arcData.servicelocations, function(randomIndex, servicelocation) {
                            var arcId = servicelocation.arcid;
                            var sequence = servicelocation.sequence;

                            // If we don't have this arcId stored, then do that
                            if (organizedData[arcId] === undefined) {
                                organizedData[arcId] = {};
                            }

                            // Find out if we've got this arcId and sequence's data already, if we do, skip this next part
                            if (organizedData[arcId][sequence] === undefined) {
                                // Make a copy of the data
                                var toAdd = JSON.parse(JSON.stringify(arcData));

                                // Remove the superfluous data
                                delete toAdd.servicelocations;

                                // Put this data into the correct location
                                toAdd.segmentData = servicelocation;

                                // Add a copy to the array
                                organizedData[arcId][sequence] = JSON.parse(JSON.stringify(toAdd));
                            }
                        });
                    });

                    // Now we've got unique arcId's and some form of segment information for them, then we can put them into the array to return
                    $.each(organizedData, function (arcId, sequenceData) {
                        $.each(sequenceData, function (randomIndex, arcData) {
                            newToReturn.push(arcData);
                        });
                    });
                    break;
            }
            toReturn = newToReturn;
        }

		return toReturn;
    };

    var setupDataTable = function (responseData, tableType, tableVariable, tableId) {
    	var parsedData = parseData(responseData.data.completionData, tableType);

        ko.mapping.fromJS(
            parsedData,
            {
                key: function (data) {
                    return ko.utils.unwrapObservable(data.isStreetServiceLocation ? data.segmentid + '/' + data.arcid : data.servicelocationuid);
                },
                create: function (options) {
                    return new Fusion.ServiceHistoryItemModel(options.data, tableVariable);
                }
            },
            viewModel.tableData[tableType]
        );

        tableVariable = $('#' + tableId + ' .data-table').DataTable();

        tableVariable.on('draw.dt', function(){
        	try {
                if (reporting !== undefined) {
                    if (reporting.servicehistory !== undefined) {
                        if (reporting.servicehistory.override !== undefined) {
                            if (reporting.servicehistory.override.core !== undefined) {
                                var overrideCore = reporting.servicehistory.override.core;
                                overrideCore.Knockout.OnDataTableRedrawn();
                            }
                        }
                    }
                }
            }
            catch (ex) {
				console.log(ex);
            }
		});

        return parsedData.length;
    };

	var setupDataAll = function(responseData) {
		return setupDataTable(responseData, 'all', allSegmentsTable, 'allSegmentsTable');
	};

	var setupDataOverride = function(responseData) {
		return setupDataTable(responseData, 'override', overrideSegmentsTable, 'overrideSegmentsTable');
	};

	var setupDataCompleted = function(responseData) {
		return setupDataTable(responseData, 'complete', completedSegmentsTable, 'completedSegmentsTable');
	};

	var setupDataIncomplete = function(responseData) {
		return setupDataTable(responseData, 'incomplete', incompleteSegmentsTable, 'incompleteSegmentsTable');
	};

	var setupDataAvailableOverride = function(responseData) {
		return setupDataTable(responseData, 'availableOverride', availableOverrideSegmentsTable, 'availableOverrideSegmentsTable');
	};

	var getRouteDetail = function(routeUid, fromDate, toDate, deviceuid){
		// This get function recieves data that is returned from RouteDetailController.php
		Fusion.reportingRouteDetailService.get(routeUid, fromDate, toDate, deviceuid, function(response){
			if (response.data.routeData !== null) {
                isStreetService = response.data.routeData.streetservice;

                viewModel.routeData(response.data.routeData);
            }

			viewModel.dates({
				from: Fusion.getLocalisedDate(new Date(response.data.dates.from)),
				to: Fusion.getLocalisedDate(new Date(response.data.dates.to))
			});

			var dataAllLength = setupDataAll(response.data);
			var dataCompletedLength = setupDataCompleted(response.data);
			var dataIncompleteLength = setupDataIncomplete(response.data);
			var dataOverrideLength = setupDataOverride(response.data);
			var dataAvailableOverrideLength = setupDataAvailableOverride(response.data);

			viewModel.totals({
                'completed': dataCompletedLength,
                'incomplete': dataIncompleteLength,
                'all': dataAllLength,
                'override': dataOverrideLength,
                'availableOverride': dataAvailableOverrideLength
            });

			viewModel.tableLoaded(true);

			if (reporting !== undefined) {
                if (reporting.servicehistory !== undefined) {
                    if (reporting.servicehistory.override !== undefined) {
                    	if (reporting.servicehistory.override.core !== undefined) {
                    		var overrideCore = reporting.servicehistory.override.core;

                    		overrideCore.SetRouteLoadedEventData(response.data.routeLoadedEventData);
                            overrideCore.SetRouteData(response.data.routeData, fromDate, toDate);
                            overrideCore.SetOriginalData(response.data.data);
                            overrideCore.Knockout.TableLoaded();
                        }
                    }
                }
            }
		});
	};
	
	return {
		init: init,
		viewModel: viewModel,
		getRouteDetail: getRouteDetail,
		downloadCSV: downloadCSV
	};
	
}();