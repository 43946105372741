var Fusion = Fusion || {};
Fusion.messagingConfigService = function() {
	
	var get = function(workspaceGroupId, success, failure, always){
		Fusion.ajax.get(
			Fusion.config.APIURL + '/messaging/config/' + workspaceGroupId,
			{},
			success,
			failure,
			always
		);	
	};
	
	var post = function(workspaceGroupId, enableMessaging, allowFreeText, emailForwardAddress, success, failure, always){
		Fusion.ajax.post(
			Fusion.config.APIURL + '/messaging/config/',
			{
				messsagingEnabled: enableMessaging,
				allowFreeText: allowFreeText,
				workspaceGroupID: workspaceGroupId,
				emailForwardAddress: emailForwardAddress
			},
			success,
			failure,
			always
		);	
	};
	
	var put = function(configId, workspaceGroupId, enableMessaging, allowFreeText, emailForwardAddress, success, failure, always){
		Fusion.ajax.put(
			Fusion.config.APIURL + '/messaging/config/' + configId,
			{
				messagingEnabled: enableMessaging,
				allowFreeText: allowFreeText,
				workspaceGroupID: workspaceGroupId,
				emailForwardAddress: emailForwardAddress
			},
			success,
			failure,
			always
		);	
	};
	
	return {
		get: get,
		post: post,
		put: put
	};
	
}();
	