//ServiceHistoryItem Model
var Fusion = Fusion || {};
Fusion.ServiceHistoryItemModel = function(data, dt) {
    var itemData = data.segmentData;

    this.isStreetServiceLocation = data.isStreetLevel;
    this.id 		  = itemData.segmentid + '/' + itemData.arcid;
    this.address      = ko.observable(itemData.address + ((itemData.town === '') ? '' : ', ' + itemData.town) + ((itemData.postcode === '') ? '' : ', ' + itemData.postcode));
    this.addressid    = ko.observable((itemData.addressid === '') ? 'N/A' : itemData.addressid);
    this.sequence     = ko.observable(itemData.sequence);

    var localizedDateTime = (data.servicedAt === 'N/A') ? data.servicedAt : Fusion.getLocalisedDate(data.servicedAt);
    this.servicedAt   = ko.observable(localizedDateTime);

    this.calendarLink = ko.computed(function(){
	    return '<a href="find.php?serviceLocationsUid=' + itemData.servicelocationsuid + '"><span class="fa fa-calendar"></span></a>';
    });

    this.overrideLink = ko.computed(function(){
        return editLink(itemData).prop('outerHTML');
    });

    this.arcid = itemData.arcid;
    this.segmentid = itemData.segmentid;
    this.routestreetname = ko.observable(itemData.routestreetname === ''? 'Unnamed' : itemData.routestreetname);

    this.dateClass = '';

    if (data.override !== undefined) {
        this.username = data.override.username;
        this.status = data.override.serviced?'Serviced':'Not Serviced';
        this.reason = data.override.reason;
        this.lastmodified = data.override.lastmodified;
        this.dateClass = 'overridenDate';

        this.editRemoveOverrideLinks = ko.computed(function(){
            var editButton = editLink();
            var deleteButton = deleteLink();

            var toReturn = $('<div></div>')
                .append(editButton)
                .append("&nbsp;")
                .append(deleteButton);

            return toReturn.prop('outerHTML');
        });
    }

    var deleteLink = function(){
        return $('<button></button>')
            .prop('type', 'button')
            .addClass('btn')
            .addClass('btn-xs')
            .addClass('btn-danger')
            .addClass('serviceHistoryOverrideDeleteButton')
            .attr('data-segmentid', itemData.segmentid)
            .attr('data-arcid', itemData.arcid)
            .attr('data-overrideid', data.override.overrideid)
            .append('Delete');
    };

    var editLink = function(){
        var button = $('<button></button>')
            .prop('type', 'button')
            .addClass('btn')
            .addClass('btn-xs')
            .addClass('btn-warning')
            .addClass('serviceHistoryOverrideEditButton')
            .attr('data-segmentid', itemData.segmentid)
            .attr('data-arcid', itemData.arcid)
            .append('Edit');

        if (data.override !== undefined) {
            button.attr('data-overrideid', data.override.overrideid);
        }

        return button;
    };
};