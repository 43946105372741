var Fusion = Fusion || {};
Fusion.address = function() {
	
	var search = function(data, success, failure, always){
		Fusion.search.execute(
			'servicelocations',
			{ //data
				searchTerm: data.searchTerm,
				workspacegroupUid: data.workspacegroupUid
			},
			success,
			failure,
			always
		);	
	};
	
	return {
		search: search
	};
	
}();	