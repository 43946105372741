var Fusion = Fusion || {};
Fusion.messagingPreDefinedService = function() {
	
	var get = function(workspaceGroupId, success, failure, always){
		Fusion.ajax.get(
			Fusion.config.APIURL + '/messaging/predefined/' + workspaceGroupId,
			{},
			success,
			failure,
			always
		);	
	};
	
	var post = function(workspaceGroupId, message, detailsType, sortOrder, success, failure, always){
		Fusion.ajax.post(
			Fusion.config.APIURL + '/messaging/predefined/',
			{
				message: message,
				detailsType: detailsType,
				sortOrder: sortOrder,
				workspaceGroupID: workspaceGroupId
			},
			success,
			failure,
			always
		);	
	};
	
	var put = function(preDefinedMessageId, workspaceGroupId, message, type, sortOrder, success, failure, always){
		Fusion.ajax.put(
			Fusion.config.APIURL + '/messaging/predefined/' + preDefinedMessageId,
			{
				workspaceGroupID: workspaceGroupId,
				message: message,
				detailsType: type,
				sortOrder: sortOrder
			},
			success,
			failure,
			always
		);	
	};
	
	var del = function(preDefinedMessageId, workspaceGroupId, success, failure, always){
		Fusion.ajax.del(
			Fusion.config.APIURL + '/messaging/predefined/' + preDefinedMessageId,
			{
				workspaceGroupID: workspaceGroupId
			},
			success,
			failure,
			always
		);	
	};
	
	return {
		get: get,
		post: post,
		put: put,
		del: del,
	};
	
}();
	