var Fusion = Fusion || {};
Fusion.findController = function(){
	
	var init = function(){
		ko.applyBindings(viewModel);
	};
	
	var doSearch = function() {
		//This method is called on text field change, dropdown change, or button click
		var searchTerm = viewModel.searchTerm();
		var workspacegroupUid = viewModel.workspacegroupUid();
		
		if(searchTerm !== undefined && searchTerm.length > 2){
			//Only go through with the search if we have a search term
			
			//Set the search in progress flag to true to show the loader
			viewModel.searchInProgress(true);
			
			var searchResults = Fusion.address.search(
				{
					//search data
					searchTerm: searchTerm, 
					workspacegroupUid: workspacegroupUid
				},
				function(response){
					//success

					//Set the search results array in viewmodel to our search response
					viewModel.searchResults(response.data);

					viewModel.searchInProgress(false);

					//Use old jquery to show the panel
					$('#search_results_panel').slideDown('fast');
				},
				function(response){
					//fail
					console.log("FindController.js, response fail:");
					console.log(response);
					viewModel.searchResults(null);
				},
				function(){
					//always
					//Set the search in progress flag to false to hide the loader regardless of error or success
					viewModel.searchInProgress(false);
				}
			);
		}
	};
	
	var getServiceHistory = function(serviceLocationUid) {
		Fusion.serviceHistory.get(
			serviceLocationUid, 
			function(response){
				//Set the service history to our observable array
				var serviceHistoryResponse = [];

				if (response.data !== undefined && !response.error) {
                    for (var i = 0; i < response.data.length; i++) {
                        response.data[i].mapUrl = Fusion.mapUrl.get(
                            'n',
                            response.data[i].routeuid,
                            '&geom=' + response.data[i].geom +
                            '&iss=' + response.data[i].issue +
                            '&addr=' + response.data[i].address
                        );

                        serviceHistoryResponse.push(response.data[i]);
                    }
                }
				
				viewModel.serviceHistory(serviceHistoryResponse);
			}
		);
	};
	
	var bindResponseToModel = function(response, result) {

		var routeData = response.data;
		
		//Reset display name & service history
		viewModel.displayName(undefined);
		viewModel.serviceHistory([]);

		if (routeData === undefined || routeData.length === 0)
		{
			// No route data found.
			viewModel.resultLoaded(true);
			viewModel.displayName('No data available');
			return;
		}

		if (typeof routeData.calendarInfo !== "undefined") {
			//Convert calendar info object to an array since it is passed back in a weird format
			routeData.calendarInfo = Object.keys(routeData.calendarInfo).map(function (key) {
				return routeData.calendarInfo[key];
			});

			//Set date arrays (was done with php in template, logic ported to js)
			if (routeData.calendarInfo.length > 0) {

				var datesArray = [];
				var changedDatesFromArray = [];
				var changedDatesToArray = [];

				for (var i = 0; i < routeData.calendarInfo.length; i++) {
					var dates = [];
					var changedDatesFrom = [];
					var changedDatesTo = [];
					var routeInfo = routeData.calendarInfo[i];
					var routeUid = routeInfo.dbData.routeuid;
					var dateInformationHolder = [];

					for (var d = 0; d < routeInfo.dates.length; d++) {
						var routeDate = routeInfo.dates[d];

						var date = {};

						// Add map url to each object
						routeInfo.dbData.mapUrl = Fusion.mapUrl.get(result.servicelocationsuids[0], routeInfo.dbData.routeuid);

						date[routeDate] = routeInfo.dbData;

						dates.push(date);

						dateInformationHolder[routeDate] = routeInfo.dbData;
					}

					if (!$.isArray(routeInfo.movedDates)) {
						$.each(routeInfo.movedDates, function (dateKey, dateInfo) {
							changedDatesFrom[dateInfo.from] = dateInfo.toReadable;

							changedDatesTo[dateInfo.to] = dateInfo.fromReadable;
						});

						changedDatesFromArray[routeUid] = changedDatesFrom;
						changedDatesToArray[routeUid] = changedDatesTo;
					}

					datesArray.push(dates);
				}

				viewModel.dates(datesArray);
				viewModel.changedDatesFrom(changedDatesFromArray);
				viewModel.changedDatesTo(changedDatesToArray);
			}
		}
		
		var routeColours     = [];
		var routeNames       = [];
		var routeStreetNames = [];
		var validRoutes = [];

		if (typeof routeData.routeInfo !== "undefined") {
			//for each route in the response add it to a resultData array
			for (var x = 0; x < routeData.routeInfo.length; x++) {
				var routeUid = routeData.routeInfo[x].routeuid;
				//Set the routes color data based on its index
				routeData.routeInfo[x].colourData = Fusion.labelColours[x % Fusion.labelColours.length];
				routeColours[routeUid] = Fusion.labelColours[x % Fusion.labelColours.length];
				validRoutes[routeUid] = true;

				//Set route names array
				routeNames[routeUid] = routeData.routeInfo[x].routename;

				//Set route street names in case of multiple road names on same route
				if (routeData.routeInfo[x].routestreetname !== '') {
					if (routeStreetNames.indexOf(routeData.routeInfo[x].routestreetname) === -1) {
						routeStreetNames.push(routeData.routeInfo[x].routestreetname);
					}
				}

				//Set the map url for the route item
				routeData.routeInfo[x].mapUrl = Fusion.mapUrl.get(result.servicelocationsuids[0], routeUid);

				//Set the display name of the result based on its data
				if (routeData.streetService === false &&
					routeData.routeInfo[x].address !== '' &&
					(routeData.routeInfo[x].town !== '' ||
						routeData.routeInfo[x].postcode !== '')) {
					viewModel.displayName(
						routeData.routeInfo[x].address +
						((routeData.routeInfo[x].town === '') ? '' : ', ' + routeData.routeInfo[x].town) +
						((routeData.routeInfo[x].postcode === '') ? '' : ', ' + routeData.routeInfo[x].postcode.toUpperCase())
					);
				}

				// push it to the observable array
				viewModel.resultData.push(routeData.routeInfo[x]);
			}

			if (viewModel.displayName() === undefined) {
				if (routeStreetNames.length > 0) {
					viewModel.displayName(routeStreetNames.join(' & '));
				}
			}
		}

		// If we've failed to set a name, try and set one
		if (viewModel.displayName() === undefined) {
			if (typeof result.address !== "undefined") {
				viewModel.displayName(result.address);
			}
			else {
				viewModel.displayName("Unknown, an error may have occurred.");
			}
		}
		
		//Assign colour and route name arrays to view model
		viewModel.routeColours(routeColours);
		viewModel.routeNames(routeNames);
		viewModel.validRoutes(validRoutes);
		
		//Get the service history for this service location
		getServiceHistory(result.servicelocationsuids);

		//Show the calendar and services since we're loaded
		viewModel.resultLoaded(true);

	};
	
	var loadResult = function(result) {
		//reset result data
		viewModel.resultLoaded(false);
		viewModel.resultData([]);
		
		//Hide address list
        $('#search_results_panel').slideUp('fast');
        
        //Show the loader 
		viewModel.resultLoading(true);

		//Grab the route data for specified service location
		Fusion.find.post(
			result.servicelocationsuids,
			result.streetservice,
			function(response){
				bindResponseToModel(response, result);
			},
			function(response){
				// Failed
				console.log(response.responseText);
			},
			function(){
				//always
				//Set the search in progress flag to false to hide the loader regardless of error or success
				viewModel.searchInProgress(false);
				viewModel.resultLoading(false);
			}
		);
	};
	
	//Set up knockout view model
	var viewModel = {
		searchInProgress: ko.observable(false),
		resultLoaded: ko.observable(false),
		resultLoading: ko.observable(false),
		workspacegroupUid: ko.observable(),
		searchTerm: ko.observable(),
		displayName: ko.observable(),
		timeData: ko.observable(),
		searchResults: ko.observableArray(),
		resultData: ko.observableArray(),
		week: ko.observableArray([0,1,2,3,4]),
		day: ko.observableArray([0,1,2,3,4,5,6]),
		dates: ko.observableArray(),
		changedDatesFrom: ko.observableArray(),
		changedDatesTo: ko.observableArray(),
		routeColours: ko.observableArray(),
		routeNames: ko.observableArray(),
		validRoutes: ko.observableArray(),
		serviceHistory: ko.observableArray(),
		sortServiceHistory: function(a, b) {
		    return a.created > b.created ? -1 : 1;  
		}
	};
	
	return {
		init: init,
		viewModel: viewModel,
		doSearch: doSearch,
		loadResult: loadResult
	};
	
}();